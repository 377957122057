import React from "react"
import Layout from "../../components/Layout/Layout"
import HomeLayout from "../../components/HomeLayout/HomeLayout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const WE = () => {
  const data = useStaticQuery(graphql`
    query {
      WE: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "WE" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <HomeLayout>
        {data.WE.nodes.map(img => (
          <Img fluid={img.childImageSharp.fluid} alt="WE images" key={img.id}/>
        ))}
      </HomeLayout>
    </Layout>
  )
}

export default WE
